@font-face {
  font-family: 'Guillon-Regular';
  src: url('../../assets/fonts/Guillon-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Guillon-Black';
  src: url('../../assets/fonts/Guillon-Black.ttf') format('truetype');
}

@font-face {
  font-family: 'Guillon-Bold';
  src: url('../../assets/fonts/Guillon-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Guillon-Italic';
  src: url('../../assets/fonts/Guillon-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'Entypo';
  src: url('../../../node_modules/react-native-vector-icons/Fonts/Entypo.ttf') format('truetype');
}

@font-face {
  font-family: 'EvilIcons';
  src: url('../../../node_modules/react-native-vector-icons/Fonts/EvilIcons.ttf') format('truetype');
}

@font-face {
  font-family: 'FontAwesome';
  src: url('../../../node_modules/react-native-vector-icons/Fonts/FontAwesome.ttf') format('truetype');
}

@font-face {
  font-family: 'fontcustom';
  src: url('../../../node_modules/react-native-vector-icons/Fonts/Foundation.ttf') format('truetype');
}

@font-face {
  font-family: 'Ionicons';
  src: url('../../../node_modules/react-native-vector-icons/Fonts/Ionicons.ttf') format('truetype');
}

@font-face {
  font-family: 'Material Design Icons';
  src: url('../../../node_modules/react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf') format('truetype');
}

@font-face {
  font-family: 'MaterialIcons';
  src: url('../../../node_modules/react-native-vector-icons/Fonts/MaterialIcons.ttf') format('truetype');
}

@font-face {
  font-family: 'Octicons';
  src: url('../../../node_modules/react-native-vector-icons/Fonts/Octicons.ttf') format('truetype');
}

@font-face {
  font-family: 'simple-line-icons';
  src: url('../../../node_modules/react-native-vector-icons/Fonts/SimpleLineIcons.ttf') format('truetype');
}

@font-face {
  font-family: 'Zocial';
  src: url('../../../node_modules/react-native-vector-icons/Fonts/Zocial.ttf') format('truetype');
}

@font-face {
  font-family: 'brighte-font-icon';
  src: url('../../../node_modules/@brighte/brighte-one-native/lib/dist/assets/fonts/brighte-font-icon.ttf')
    format('truetype');
}
